<template>
  <div>
    <!-- begin:: "Go Back" button -->
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a asignaturas
    </v-btn>
    <!-- end:: "Go Back" button -->
    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin:: Page main title -->
        <v-row>
          <v-col cols="12" class="pa-0">
            <p
              class="display-1 font-weight-medium text-center text-md-left text-blue-grey lighten-2 mb-1 mt-3"
            >
              Resumen de notas
            </p>
          </v-col>
        </v-row>
        <!-- end:: Page main title -->

        <!-- begin:: Page description -->
        <v-row>
          <v-col cols="12" class="pa-0">
            <p
              class="d-block text-body-1 text-muted pt-2 text-center text-md-left font-weight-normal"
            >
              Resumen de notas de evaluacion seperadas por asignaturas
              académicas y modulares. Puede hacer click sobre un perfil o una
              actividad para conocer más detalles.
            </p>
          </v-col>
        </v-row>
        <!-- end:: Page description -->

        <!-- begin:: asignaturas académicas -->
        <v-row class="my-4">
          <p class="ma-0 pa-0 headline mx-4">Académicas</p>
          <v-divider></v-divider>
        </v-row>

        <SubjectStageSummaryContainer
          v-if="!evaluation.isLoadingAcademic"
          :evaluation="evaluation.data.academic"
        >
        </SubjectStageSummaryContainer>

        <div style="height: 30vh;" v-if="evaluation.isLoadingAcademic">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Cargando resumen de asignaturas acdémicas
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="blue darken-1"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
        <!-- end:: asignaturas académicas -->

        <!-- begin:: Módulos -->
        <v-row class="mt-16 mb-4">
          <p class="ma-0 pa-0 headline mx-4">Módulos</p>
          <v-divider></v-divider>
        </v-row>

        <SubjectStageSummaryContainer
          v-if="!evaluation.isLoadingTechnical"
          :evaluation="evaluation.data.technical"
        >
        </SubjectStageSummaryContainer>

        <div style="height: 30vh;" v-if="evaluation.isLoadingTechnical">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Cargando resumen de módulos
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="blue darken-1"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
        <!-- begin:: Módulos -->
      </template>
    </div>
  </div>
</template>

<script>
import SubjectStageSummaryContainer from "@/view/components/evaluation/summary/SubjectStageSummary.vue";
import evaluationRepository from "@/repositories/evaluationRepository.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PURGE_SUBJECT } from "@/core/services/store/subject.module";
export default {
  name: "EvaluationSummary",
  components: {
    //The summary container, contains the subject card with scores
    SubjectStageSummaryContainer,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Notas", route: "view_subjects_modules" },
      { title: "Resumen de notas", route: "evaluation_summary" },
    ]);
    this.getAllAcademicScores();
    this.getAllTechnicalScores();
    //this.getAllScores();
  },
  data() {
    return {
      evaluation: {
        isLoadingAcademic: "",
        isLoadingTechnical: "",
        data: {
          academic: [],
          technical: [],
        },
      },
    };
  },
  methods: {
    // Get request to fetch all Academic evaluations scores
    getAllAcademicScores() {
      this.evaluation.isLoadingAcademic = true;
      evaluationRepository
        .getAllAcademicEvaluationScores()
        .then(({ data }) => {
          this.evaluation.data.academic = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.evaluation.isLoadingAcademic = false;
        });
    },

    // Get request to fetch all Technical evaluations scores
    getAllTechnicalScores() {
      this.evaluation.isLoadingTechnical = true;
      evaluationRepository
        .getAllTechnicalEvaluationScores()
        .then(({ data }) => {
          this.evaluation.data.technical = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.evaluation.isLoadingTechnical = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    this.$store.dispatch(PURGE_SUBJECT);
  },
};
</script>
